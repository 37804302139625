import { Link } from 'gatsby'
import React from "react"
import styled, { createGlobalStyle } from "@xstyled/styled-components"

import { Col, Container, Row } from 'reactstrap'

import 'bootstrap/scss/bootstrap.scss'

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby {
    display: flex;
    min-height: 100vh;
    width: 100%;
  }
  #___gatsby > div {
    display: flex;
    min-height: 100vh;
    width: 100%;
  }
`

const StyledContainer = styled(Container)`
  height: 100%;
`

const NotFoundPage = () => [
  <GlobalStyle key="global" />,
  <StyledContainer key="container">
    <Row className="h-100">
      <Col className="h-100 d-flex flex-column align-items-center justify-content-center text-center">
        <h1>NOT FOUND</h1>
        <Link className="btn btn-primary btn-lg m-3" to="/">Home</Link>
      </Col>
    </Row>
  </StyledContainer>
]

export default NotFoundPage
